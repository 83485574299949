import React, { useEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { PrismicTextObj, PrismicSliceProps } from '@models/common.model'
import { PrismicImage } from '@models/image.models'
import { TrailFadeUp, SpringFadeUp } from '@utils/animations'
import {
  ContentBox,
  TitleBox,
  FeatureMediaWrapper,
  DescriptionBox,
  StyledContainerBox,
  DescriptionBoxWithPaypalButtons,
} from './style'

import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '@prismic/linkResolverHelper'
import { Box, Flex } from '@rebass/grid'
import { PayPalButtons } from '@paypal/react-paypal-js'
import { SectionTitle } from '@slices/article-list-new/style'

export interface ContentWithImagePrimaryProps {
  id: string
  section_headline: PrismicTextObj
  section_content: PrismicTextObj
  section_read_more_content: PrismicTextObj
  section_image: PrismicImage
  image_side: 'left' | 'right'
  code: PrismicTextObj
}

export type ContentWithImageProps =
  PrismicSliceProps<ContentWithImagePrimaryProps>

export const ContentWithImageSlice: React.FC<ContentWithImageProps> = ({
  props, title
}) => {
  const { primary, items } = props

  const [state, setState] = useState({
    showReadMore: false,
  })

  const toggleReadMore = () => {
    setState({
      showReadMore: !state.showReadMore,
    })
  }

  const renderedEvents = items?.length
    ? items.map((item: any, index: number) => {
      return (
        <DescriptionBoxWithPaypalButtons
          key={`event-${item.book_now_id + index}`}
          mb={'2rem'}
        >
          <TrailFadeUp delay={200}>
            <RichText
              render={item.events.richText}
              linkResolver={linkResolver}
            />
            {Boolean(
              item.event_description && item.price && item.book_now_id,
            ) && (
              <>
                <PayPalButtons
                  className="event-button"
                  style={{
                    shape: 'pill',
                    color: 'white',
                    layout: 'vertical',
                    label: 'buynow',
                    // tagline: true,
                  }}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          description: item.event_description,
                          amount: {
                            currency_code: 'EUR',
                            value: String(item.price),
                            breakdown: {
                              item_total: {
                                currency_code: 'EUR',
                                value: Number(item.price / 1.19).toFixed(2),
                              },
                              shipping: {
                                currency_code: 'EUR',
                                value: '0',
                              },
                              tax_total: {
                                currency_code: 'EUR',
                                value: Number(
                                  item.price - item.price / 1.19,
                                ).toFixed(2),
                              },
                            },
                          },
                        },
                      ],
                    })
                  }}
                  onApprove={(_, actions) => {
                    return actions.order!.capture().then(details => {
                      // Show a success message within this page, e.g.
                      const element = document.getElementById(item.book_now_id)
                      if (element) {
                        element.innerHTML = ''
                        element.innerHTML =
                          '<h3>Thank you for your payment!</h3>'
                      }
                      // Or go to another URL:  actions.redirect('thank_you.html');
                    })
                  }}
                />

                <div id="smart-button-container">
                  <div className="event-button">
                    <div id={item.book_now_id}></div>
                  </div>
                </div>
              </>
            )}
          </TrailFadeUp>
        </DescriptionBoxWithPaypalButtons>
      )
    })
    : null

  const leftElement = (key: string) => (
    <Box key={`leftElement-${key}`} width={[1, 1, 1, 6 / 13]}>
      {Boolean(primary.section_headline.richText.length) && (
        <TitleBox mb={'1rem'}>
          <SpringFadeUp delay={200}>
            <RichText
              linkResolver={linkResolver}
              render={primary.section_headline.richText}
            />
          </SpringFadeUp>
        </TitleBox>
      )}
      <ContentBox>
        <DescriptionBox mb={'1rem'}>
          <TrailFadeUp delay={200}>
            <RichText
              render={primary.section_content.richText}
              linkResolver={linkResolver}
            />
          </TrailFadeUp>
        </DescriptionBox>
        {renderedEvents}
        {primary.section_read_more_content?.text && (
          <>
            <div
              id="readmore"
              className={
                state.showReadMore ? 'read-more is-visible' : 'read-more'
              }
            >
              <RichText
                linkResolver={linkResolver}
                render={primary.section_read_more_content.richText}
              />
            </div>
            <a className="u-clickable" onClick={toggleReadMore}>
              {state.showReadMore ? 'Read less' : 'Read more'}
            </a>
          </>
        )}
      </ContentBox>
    </Box>
  )

  const rightElement = (key: string) => (
    <Box key={`rightElement-${key}`} width={[1, 1, 1, 6 / 13]}>
      <FeatureMediaWrapper
        className="image-wrapper"
        flexDirection={'column'}
        justifyContent="center"
      >
        <SpringFadeUp>
          <GatsbyImage
            image={primary.section_image?.gatsbyImageData}
            alt={primary.section_image?.alt || 'image with content'}
          />
        </SpringFadeUp>
      </FeatureMediaWrapper>
    </Box>
  )

  // Image Left
  return (
    <StyledContainerBox as="section" className="content-with-image">
      {title && (
        <SpringFadeUp>
          <SectionTitle
            // style={{ fontSize: '61px', marginBottom: '48px' }}
            className="u-text-decoration-underlined"
          >
            {title}
          </SectionTitle>
        </SpringFadeUp>
      )}
      <TrailFadeUp>
        <Flex
          className={`content-with-image-${primary?.image_side}`}
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {primary?.image_side === 'left'
            ? [
                rightElement(primary.id + primary?.image_side),
                leftElement(primary.id + primary?.image_side),
              ]
            : [
                leftElement(primary.id + primary?.image_side),
                rightElement(primary.id + primary?.image_side),
              ]}
        </Flex>
      </TrailFadeUp>
    </StyledContainerBox>
  )
}

export const query = graphql`
  fragment contentWithImageSubpage on PrismicSubpageDataBodyContentWithImage {
    id
    slice_label
    slice_type
    primary {
      image_side
      section_image {
        gatsbyImageData
        alt
      }
      section_headline {
        richText
      }
      section_content {
        richText
      }
      section_read_more_content {
        richText
        text
      }
    }
    items {
      book_now_id
      event_description
      events {
        richText
      }
      price
    }
  }
  fragment contentWithImageNewsPost on PrismicNewsPostDataBodyContentWithImage {
    id
    slice_label
    slice_type
    primary {
      image_side
      section_image {
        gatsbyImageData
        alt
      }
      section_headline {
        richText
      }
      section_content {
        richText
      }
    }
    items {
      book_now_id
      event_description
      events {
        richText
      }
      price
    }
  }
  fragment contentWithImageWorkshopsPost on PrismicWorkshopsPostDataBodyContentWithImage {
    id
    slice_label
    slice_type
    primary {
      image_side
      section_image {
        gatsbyImageData
        alt
      }
      section_headline {
        richText
      }
      section_content {
        richText
      }
    }
  }
  fragment contentWithImageWorkshopsArchive on PrismicWorkshopsArchiveDataBodyContentWithImage {
    id
    slice_label
    slice_type
    primary {
      image_side
      section_image {
        gatsbyImageData
        alt
      }
      section_headline {
        richText
      }
      section_content {
        richText
      }
    }
  }
`
