import { breakpoint } from '@styles/02-tools/tools.breakpoints'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/grid/emotion'

import { ContainerBox } from '@components/container'
import { typography } from '@styles/text'

export const StyledContainerBox = styled(ContainerBox)`
  background-color: ${typography.background};
  color: ${typography.text};
    margin-bottom: 8rem;

  ${breakpoint.lt('md')`
  .content-with-image-right {
    flex-direction: column-reverse;
    margin-bottom: 4rem;
  }
  `}
`

export const ContentBox = styled(Box)`
  color: ${typography.text};

    a {
    color: #007bdb;

    .link.with.arrow {
      border: 1px solid ${typography.text};
      color: ${typography.text};
    }
  }

    .read-more {
    position: relative;
    display: none;

    &.is-visible {
      display: block;
    }
  }
`

export const TitleBox = styled(Box)`
  color: ${typography.text};
`

export const DescriptionBox = styled(Box)`

  ${breakpoint.gt('md')`
    text-align: left;
  `}


  h4, h5, h6 {
    // margin-top: 4.375rem;
    margin-bottom: 2.5rem;
  }

  p:not(:last-child) {
    margin-bottom: 1rem;
  }

  color: ${typography.text};

  em {
    font-style: italic;
  }

  strong {
    font-weight: bolder;
  }


`

export const DescriptionBoxWithPaypalButtons = styled(Box)`
  .event-button {
    margin-top: 1rem;
    width: 130px;
  }
`

export const FeatureMediaWrapper = styled(Flex)`

     padding-bottom: 2rem;

  &, * {height: 100%;}

  .gatsby-image-wrapper  {
     border-radius: 12px;
  }



`

export const DetailsList = styled.ul`
  ${breakpoint.lt('md')`
      margin: 4rem 0 0 ;
      padding: 0;
  `}

  li {
    border-bottom: 1px solid #6b6a6a;
    padding-bottom: 0.5rem;

    &:not(:last-of-type) {
      margin-bottom: 1.25rem;
    }

    p {
      display: flex;
      justify-content: space-between;
      span:first-of-type {
        color: #6b6a6a;
      }
    }
  }
`
